import ApiInstance from "../configs/ApiInstance";

async function GetAllArticles() {
  try {
    const response = await ApiInstance.articleService.get();
    const { content, totalPages } = response.data;
    return { content, totalPages };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default GetAllArticles;
