import ApiInstance from "../configs/ApiInstance";

async function AddComment(articleId, nickName, comment, updatedAt, profileImage) {
  try {
    const likeCount = 0;
    const response = await ApiInstance.articleService.post(`${articleId}/comments`, {
        articleId,
        nickName,
        comment,
        updatedAt,
        likeCount,
        profileImage
        });
     return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default AddComment;
