import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Screen from './Screen';
import Entertainment from './components/Entertainment';


function App() {
  return (
    <Router>
        <Routes>
            <Route path='/entertainment/' element={<Screen/>} />
            <Route path='/entertainment/:id' element={<Entertainment/>} />
        </Routes>
    </Router>
  )
}

export default App