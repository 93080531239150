import ApiInstance from "../configs/ApiInstance";

async function FetchProfileImage(profileImageKey) {
    try {
       const response = await ApiInstance.userAwsService.get(`/users/profile-image/${profileImageKey}`);
       const imageUrl = response.data.url;
       return imageUrl;
    } catch (error) {
        console.log("Error occurred when getting user profile image",  error)
        throw error;
    }
}

export default FetchProfileImage;
