import React, { useEffect, useState } from 'react';
import { Grid, Container, CssBaseline } from "@mui/material";
import Pagination from './components/Pagination'
import EntertainmentGrid from './components/EntertainmentGrid'
import GetAllArticles from './services/GetAllArticles'
import EntertainmentGridBanner from './components/EntertainmentGridBanner'

function Screen() {

    const [articles, setArticles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const { content, totalPages } = await GetAllArticles();
                const entertainmentArticles = content.filter(article => article.category === "ENTERTAINMENT");
                    setArticles(entertainmentArticles);
                    setTotalPages(totalPages);
                return cafeData;
            } catch (error) {

            }
        }
        fetchArticles();
    }, [])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    return (
        <Container disableGutters maxWidth="2xl">
            <CssBaseline />
            <EntertainmentGridBanner/>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <EntertainmentGrid articles={articles} />
                </Grid>
                <Grid item xs={12} >
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange} />
                </Grid>
            </Grid>
        </Container>
    ) 
}

export default Screen