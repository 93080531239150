import ApiInstance from "../configs/ApiInstance";

async function LikeComment(articleId, commentId, id) {
  try {
    const response = await ApiInstance.articleService.put(`${articleId}/comments/${commentId}/like`, {
      userId: id, // Pass the userId correctly
    });
    return response;
  } catch (error) {
    console.error('Error liking comment:', error);
    throw error;
  }
}

export default LikeComment;
