import React, { useState } from "react";
import {
    Box,
    Grid,
    Container,
    TextField,
    InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import './styles/input.css'

function Filter({ onSearch }) {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        onSearch(query);
    };

    return (
        <Container sx={{ mb: 4 }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <TextField
                        className="gradient-textfield"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search"
                        variant="outlined"
                        fullWidth
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "1px solid transparent",
                                    borderImage: "linear-gradient(90deg, rgba(218, 7, 28, 0.9), rgba(241, 223, 30, 0.9)) 1",
                                },
                                "&:hover fieldset": {
                                    border: "1.5px solid transparent",
                                    borderImage: "linear-gradient(90deg, rgba(218, 7, 28, 0.9), rgba(241, 223, 30, 0.9)) 1",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "1.5px solid transparent",
                                    borderImage: "linear-gradient(90deg, rgba(218, 7, 28, 0.9), rgba(241, 223, 30, 0.9)) 1",
                                },
                                backgroundColor: "transparent",
                            },
                            input: {
                                color: "black",
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Filter;
